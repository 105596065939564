import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectFitEnum } from 'app/config/constants';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { ExternalContent } from 'app/model/entity/external-content';
import { DialogService } from 'app/service/dialog.service';
import { ExternalContentService } from 'app/service/external-content-service';
import { PictureAreaService } from 'app/service/picture-area-service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
import { WeatherContentDetailService } from 'app/service/weather-content-detail.service';

@Component({
  selector: 'dialog-setting-external-content',
  templateUrl: './dialog-setting-external-content.component.html',
  styleUrls: ['./dialog-setting-external-content.component.scss']
})
export class DialogSettingExternalContentComponent implements OnInit {
  externalContents: ExternalContent[];
  dataExternalSettings: DataExternalSetting[];
  private commonObject: Common;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataExternalSettingsOfTemplate: any,
    private externalContentService: ExternalContentService,
    public dialogRef: MatDialogRef<DialogSettingExternalContentComponent>,
    private dialogService: DialogService,
    private pictureAreaService: PictureAreaService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private weatherContentDetailService: WeatherContentDetailService
  ) {
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.dataExternalSettings = _.cloneDeep(this.dataExternalSettingsOfTemplate);
    this.externalContentService.getAllExternalContent().subscribe(externalContents => {
      this.externalContents = Helper.convertDataExternalContents(externalContents);
      const account = this.commonObject.tenantName.toUpperCase();
      this.weatherContentDetailService.callApiAwsEntry('get', account, '', false).subscribe(
        data => {
          this.externalContents = this.filterExternalContentsByApiResponse(data, this.externalContents);
        },
        error => {
          console.log(error);
          this.externalContents = this.externalContents.filter(e => e && e.contentType !== 'OpenWeather');
        }
      );
    });
  }

  /**
   * filterExternalContentsByApiResponse
   * @param data response API
   * @param externalContents
   * @returns
   */
  filterExternalContentsByApiResponse(data: any, externalContents: any[]): any[] {
    try {
      let validData = [];

      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {
          console.error(e);
        }
      }

      if (data && typeof data === 'object' && typeof data.status === 'string') {
        try {
          validData = JSON.parse(data.status);
        } catch (e) {
          console.error(e);
        }
      } else if (Array.isArray(data)) {
        validData = data;
      } else if (data && typeof data === 'object' && data.body && typeof data.body === 'string') {
        try {
          const parsedBody = JSON.parse(data.body);
          if (Array.isArray(parsedBody)) {
            validData = parsedBody;
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (Array.isArray(validData) && validData.length > 0) {
        const validItems = validData.filter(item => item && typeof item === 'object' && item.outputFile);

        if (validItems.length > 0) {
          const outputFiles = new Set(validItems.map(item => item.outputFile));

          return externalContents.filter(content => {
            if (!content) return false;

            // Nếu là OpenWeather, chỉ giữ lại những content có outputFileName nằm trong outputFiles
            if (content.contentType === 'OpenWeather') {
              return content.contentOutputFileName && outputFiles.has(content.contentOutputFileName);
            }

            // Giữ lại tất cả các content không phải OpenWeather
            return content.contentType !== 'OpenWeather';
          });
        } else {
          return externalContents.filter(e => e && e.contentType !== 'OpenWeather');
        }
      } else {
        return externalContents.filter(e => e && e.contentType !== 'OpenWeather');
      }
    } catch (error) {
      return externalContents.filter(e => e && e.contentType !== 'OpenWeather');
    }
  }

  /**
   * get list data different size with image
   * @param dataExternalSettings
   * @param externalContents
   * @returns list data different size with image
   */
  private getDataExternalSettingsDifferentSize(
    dataExternalSettings: DataExternalSetting[],
    externalContents: ExternalContent[]
  ): Array<DataExternalSetting> {
    let dataExternalSettingsDifferentSize = new Array<DataExternalSetting>();
    dataExternalSettings.forEach(dataExternalSetting => {
      let externalContentSelected = externalContents.find(external => external.id == dataExternalSetting.idExternalContent);
      // compare
      if (externalContentSelected && !this.compareTwoSize(dataExternalSetting, externalContentSelected)) {
        dataExternalSettingsDifferentSize.push(dataExternalSetting);
      }
    });
    return dataExternalSettingsDifferentSize;
  }

  /**
   * compare size area and size image external content
   * @param dataExternalSetting
   * @param externalContentSelected
   * @returns true if equals size
   */
  private compareTwoSize(dataExternalSetting: DataExternalSetting, externalContentSelected: ExternalContent): boolean {
    return (
      dataExternalSetting['width'] == externalContentSelected['width'] && dataExternalSetting['height'] == externalContentSelected['height']
    );
  }

  /**
   * send data back to save
   */
  saveDataExternal() {
    // get list dataExternalSetting choose
    let dataResults = this.dataExternalSettings.filter(data => data.idExternalContent != -1);
    // get data different size with image
    let dataExternalSettingsDifferentSize = this.getDataExternalSettingsDifferentSize(dataResults, this.externalContents);
    // if all is same size with image
    if (dataExternalSettingsDifferentSize.length == 0) {
      this.dialogRef.close(this.dataExternalSettings);
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('dialog-setting-external-content.area-different-size'),
            button1: this.translateService.instant('dialog-setting-external-content.yes'),
            button2: this.translateService.instant('dialog-setting-external-content.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          // update area to fill
          let idsArea = dataExternalSettingsDifferentSize.filter(data => data['objectFit'] != ObjectFitEnum.FILL).map(data => data.idArea);
          if (idsArea.length) {
            this.pictureAreaService.updateObjectFitToFill(idsArea).toPromise();
          }
          // send back data to save
          this.dialogRef.close(this.dataExternalSettings);
        }
      );
    }
  }
}

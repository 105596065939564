import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Stock } from 'app/model/entity/ticket-editor/stock';
import { CommonService } from 'app/service/common.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';

@Component({
  selector: 'dialog-set-up-a-refund-certificate',
  templateUrl: './dialog-set-up-a-refund-certificate.component.html',
  styleUrls: ['./dialog-set-up-a-refund-certificate.component.scss']
})
export class DialogSetUpARefundCertificateComponent implements OnInit {
  isConfirm: boolean;
  isSuccess: boolean;
  currentLang: string;
  readonly USER_REFUND_ENABLED = 'stockUserRefundEnabled';
  readonly BOUGHT = 'bought';
  constructor(
    public translateService: TranslateService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<DialogSetUpARefundCertificateComponent>,
    private cdr: ChangeDetectorRef,
    private ticketManagerService: TicketManagerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {}

  /**
   * changeConfirmStatus
   */
  changeConfirmStatus(): void {
    this.isConfirm = !this.isConfirm;
  }

  /**
   * changeRefunds
   */
  changeRefunds(): void {
    let type = this.data?.mode == 1 ? this.USER_REFUND_ENABLED : this.BOUGHT;
    this.ticketManagerService.changeRefunds(this.data?.stock?.stockId, type).subscribe(res => {
      this.isSuccess = true;
      this.cdr.detectChanges();
    });
  }

  /**
   * handleSuccess
   */
  handleSuccess() {
    this.dialogRef.close(true);
  }
}

export interface DialogData {
  mode: number;
  stock: Stock;
}
